<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <div class="car-div">
          <!-- 轮播图 -->
          <el-carousel trigger="click" height="480px" :interval="300000">
            <el-carousel-item v-for="item in imgs_Carousel" :key="item.id">
              <img class="car-imgs" :src="item.imgs" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="_blank"></div>
        <el-card style="background-color: #ddf1ff">
          <div style="width: 90%; margin-right: auto; margin-left: auto">
            <h2 style="margin-left: 20px; margin-block: 10px">产品概述</h2>
          </div>
          <div style="padding: 20px">
            <el-row :gutter="20" justify="center" type="flex">
              <el-col :span="5">
                <div class="go-product-page" @click="goProductPage()">
                  <div class="grid-content bg-purple">
                    <div class="icon">
                      <i class="iconfont icon-oabangong-copy"></i>
                    </div>
                    <h2>OA办公系统</h2>
                    <p class="product-p">
                      铭和OA办公系统是由江苏淮海数码科技有限公司和江苏科大汇峰科技有限公司联合研发的以医院办公自动化为方向的综合管理办公平台。
                      铭和OA办公系统通过融合不同信息化资源，提供包括流程审批、行政办公、日常事务、数据统计分析、即时通讯、移动办公等服务，
                      帮助企业降低沟通和管理成本，提升生产和决策效率。
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="go-product-page" @click="goProductPage()">
                  <div class="grid-content bg-purple">
                    <div class="icon">
                      <i class="iconfont icon-yiyuan1"></i>
                    </div>
                    <h2>健康管理系统</h2>
                    <p class="product-p">
                      健康管理系统能实现医疗机构现有的信息系统进行数据连接的能力，
                      能利用慢病风险评估模型，对收集到的客户数据进行疾病评估以及相关危险因素分析，
                      利用互联网对大数据和云计算的处理方式，
                      实现对检前、检中、检后及住院患者后续的慢病等全过程的管理。
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="go-product-page" @click="goProductPage()">
                  <div class="grid-content bg-purple">
                    <div class="icon">
                      <i class="iconfont icon-yiyuan"></i>
                    </div>
                    <h2>“医养康”信息平台</h2>
                    <p class="product-p">
                      互联网+养老、智慧健康养老、标准化建设、人才培养、养老服务补贴、金融支持、税费优惠等各个方面都提供配套扶持政策。
                      未来我国将建成“以居家为基础、社区为依托、机构为补充、医养相结合的养老服务体系”。
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="go-product-page" @click="goProductPage()">
                  <div class="grid-content bg-purple">
                    <div class="icon">
                      <i class="iconfont icon-weixinkaifa"></i>
                    </div>
                    <h2>微信小程序</h2>
                    <p class="product-p">
                      提供微信小程序开发业务，满足中小企业定制化服务。
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <div class="_blank"></div>
        <el-card style="background-color: #d3e9f8">
          <div style="width: 90%; margin-right: auto; margin-left: auto">
            <h2 style="margin-left: 20px; margin-block: 10px">解决方案</h2>
          </div>
          <div class="main_Solve">
            <el-tabs type="border-card" tab-position="left">
              <el-tab-pane label="民营医院OA办公系统">
                <div class="solve_text">
                  <h2>民营医院OA办公系统</h2>
                  <h3>协同高效一体化办公</h3>
                  <p>
                    提供统一通讯功能，多端消息同步更新，随时随地交流工作，提高个人效能。
                    集行政办公于一体，多部门统一协调，提高办事效率。
                  </p>
                  <h3>民营医院专项服务</h3>
                  <p>
                    提供排班管理、督办管理、科研项目管理、医院内部信息系统专项对接等特色功能模块。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="健康信息管理系统">
                <div class="solve_text">
                  <h2>健康信息管理系统</h2>
                  <h3>自助健康行动方案</h3>
                  <p>
                    实现个人自助管理健康的平台，支持个人查看全部健康档案、维护个人健康信息、与健康管理师互动咨询等；
                  </p>
                  <h3>档案管理</h3>
                  <p>
                    通过体检报告、问卷调查等方式获取管理对象各方面健康信息，建立健康档案，实现档案归并和对比。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="“医养康”养老服务中心">
                <div class="solve_text">
                  <h2>“医养康”养老服务中心</h2>
                  <h3>“医养康”有机结合</h3>
                  <p>国家级试点实验单位，“医养康”行业领先级解决方案提供者。</p>
                  <h3>互联网+养老</h3>
                  <p>
                    “以居家为基础、社区为依托、机构为补充、医养相结合的养老服务体系”。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="中小企业定制化开发">
                <div class="solve_text">
                  <h2>中小企业定制化开发</h2>
                  <h3>灵活高效</h3>
                  <p>
                    以解决问题为宗旨，适配企业差异化需求，高效完成定制化开发。
                  </p>
                  <h3>多平台实现方案</h3>
                  <p>
                    以微信公众号、小程序为主要开发平台，兼顾PC端、移动APP等。
                  </p>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
        <el-card>
          <div>
            <div style="width: 90%; margin-right: auto; margin-left: auto">
              <h2 style="margin-left: 20px; margin-block: 10px">主营业务</h2>
              <p style="margin-left: 20px; margin-block: 10px; font-size: 15px">
                计算机软件硬件研发与销售；计算机、通信设备、网络设备销售及服务；第三类医疗器械经营、第一类医疗器械销售、第二类医疗器械销售；物业管理等。
              </p>
              <br />
            </div>
            <div class="join_Environment">
              <el-row :gutter="20" align="middle" type="flex">
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/solve_img2.jpg"
                      class="join_Image4"
                    />
                    <span>计算机软硬件研发</span>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/solve_img2.jpg"
                      class="join_Image4"
                    />
                    <span>通信网络设备销售与服务</span>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/solve_img3.jpg"
                      class="join_Image4"
                    />
                    <span>医疗器械销售</span>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/solve_img4.jpg"
                      class="join_Image4"
                    />
                    <span>物业管理</span>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>


<script>
import Header01 from "../components/Header01.vue";
import Foot01 from "../components/Foot01.vue";
export default {
  data() {
    return {
      imgs_Carousel: [
        {
          id: 1,
          imgs: require("../assets/imgs/Carousel_map1.jpg"),
        },
        {
          id: 2,
          imgs: require("../assets/imgs/Carousel_map2.jpg"),
        },
        {
          id: 3,
          imgs: require("../assets/imgs/Carousel_map3.jpg"),
        },
        {
          id: 4,
          imgs: require("../assets/imgs/Carousel_map4.jpg"),
        },
      ],
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },

  created() {},
  methods: {
    goProductPage() {
      // console.log("kkk");
      this.$router.push("/oa_office");
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.page-box {
  // height: 100vh;
  margin-top: 10px;
}

.el-main {
  padding: 0;
}

//轮播图图片格式
.car-imgs {
  width: 100%;
  height: 100%;
}
//轮播图盒子
.car-div {
  padding: 10px;
}

._blank {
  height: 20px;
}
// 产品概述样式
.h_Product {
  padding-left: 20px;
  font-size: 20px;
}
//图标样式
.icon {
  padding: 5px;
}
.product-p {
  font-size: 16px;
  padding: 10px;
  text-align: justify;
  text-indent: 30px;
}
// 设置产品概述盒子的样式，鼠标经过切换样式，边框变色
.go-product-page {
  box-sizing: border-box;
  cursor: pointer; /*鼠标变成手指样式*/
  border: 1px solid #fff;
}
.go-product-page:hover {
  border: 1px solid #6633ff;
}
//解决方案盒子
.main_Solve {
  margin: 0 auto;
  max-width: 1550px;
  padding-bottom: 16px;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.bg-purple-dark {
  background: #000;
}
.bg-purple {
  background: #fff;
}
.bg-purple-light {
  background: #e5e9f2;
}
.solve_text {
  width: 90%;
  margin: 0 auto;
  line-height: 2;
  // font-size: 14px;

  // text-indent: 25px;
}
.solve_text > h2 {
  font-size: 18px;
}
.solve_text > h3 {
  text-indent: 2px;
  font-size: 15px;
}
.solve_text > p {
  text-indent: 28px;
  font-size: 15px;
}
// 产品概述详情div样式
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  height: 250px;
  overflow-y: auto;
}

.join_Environment {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.join_Image4 {
  max-width: 400px;
}
</style>