<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <el-card>
          <div>
            <div class="bc_Div01"></div>
          </div>
        </el-card>
        <div class="bc_Tabs">
          <el-tabs
            v-model="activeName"
            style="font-size: 16px"
            tab-position="left"
          >
            <el-tab-pane label="公司介绍" name="first">
              <span class="about-tab-style" slot="label"
                ><i class="iconfont icon-gongsijianjieicon"></i> 公司介绍</span
              >
              <div class="bc_Text">
                <div class="tab-pane-box">
                  <h2>江苏淮海数码科技有限公司</h2>
                  <p>
                    江苏淮海数码科技有限公司成立于2010年8月，注册资金500万元，是南京铭和医院投资管理有限公司麾下的唯一高科技IT企业。
                    集团下设沭阳铭和医院、沭阳铭和康复医院、沭阳县铭和养老服务中心、沭阳北丁集医院、江苏淮海数码科技有限公司等企业。
                    我公司以集团内部使用需求为基础，专注研发医疗、康复、养老等领域的信息化管理软件，把提升院内职工工作效率、降低工作流程
                    复杂度、提升患者就医体验感等作为主要开发目标。我们从业务和技术领域，依托不断积累的经验和先进技术，建立快速开发框架、移动
                    开发框架、微服务开发框架等，实现快速为客户定制需求，并结合科学的架构，使用敏捷的迭代式开发方法，快速实现客户的产品需求，
                    注重提高产品服务能力的同时，提升竞争力。差异化定制的软件产品，将为越来越多的企业客户带来巨大的效益提升。
                  </p>
                  <img src="../assets/imgs/about/fuben-640-640.jpg" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="荣誉资质" name="second">
              <span class="about-tab-style" slot="label"
                ><i class="iconfont icon-rongyu"></i> 荣誉资质</span
              >
              <div class="bc_Text">
                <div class="tab-pane-box">
                  <h2>江苏淮海数码科技有限公司</h2>
                  <p>
                    江苏淮海数码科技有限公司成立于2010年8月，注册资金500万元，是南京铭和医院投资管理有限公司麾下的唯一高科技IT企业。
                    集团下设沭阳铭和医院、沭阳铭和康复医院、沭阳县铭和养老服务中心、沭阳北丁集医院、江苏淮海数码科技有限公司等企业。
                    我公司以集团内部使用需求为基础，专注研发医疗、康复、养老等领域的信息化管理软件，把提升院内职工工作效率、降低工作流程
                    复杂度、提升患者就医体验感等作为主要开发目标。我们从业务和技术领域，依托不断积累的经验和先进技术，建立快速开发框架、移动
                    开发框架、微服务开发框架等，实现快速为客户定制需求，并结合科学的架构，使用敏捷的迭代式开发方法，快速实现客户的产品需求，
                    注重提高产品服务能力的同时，提升竞争力。差异化定制的软件产品，将为越来越多的企业客户带来巨大的效益提升。
                  </p>
                  <img src="../assets/imgs/about/fuben-640-640.jpg" alt="" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="公司公告" name="third">
              <span class="about-tab-style" slot="label"
                ><i class="iconfont icon-gonggao"></i> 经营理念</span
              >
              <div class="tab-pane-box">
                <p><span class="tab-pane-title">公司文化：</span>人文为怀，科技汇海</p>
                <p><span class="tab-pane-title">愿 景：</span>中国医疗行业管理软件领导品牌</p>
                <p><span class="tab-pane-title">价值观：</span>人无我有，人有我新，人新我精</p>
                <img src="../assets/imgs/about/Snipaste_2021-09-07_16-27-07.jpg" alt="">
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span class="about-tab-style" slot="label"
                ><i class="iconfont icon-lianxiwomen"></i> 联系我们</span
              >
              <div class="bc_Text">
                <div class="tab-pane-box">
                  <h2>江苏淮海数码科技有限公司</h2>
                  <p>
                    江苏淮海数码科技有限公司成立于2010年8月，注册资金500万元，是南京铭和医院投资管理有限公司麾下的唯一高科技IT企业。
                    集团下设沭阳铭和医院、沭阳铭和康复医院、沭阳县铭和养老服务中心、沭阳北丁集医院、江苏淮海数码科技有限公司等企业。
                    我公司以集团内部使用需求为基础，专注研发医疗、康复、养老等领域的信息化管理软件，把提升院内职工工作效率、降低工作流程
                    复杂度、提升患者就医体验感等作为主要开发目标。我们从业务和技术领域，依托不断积累的经验和先进技术，建立快速开发框架、移动
                    开发框架、微服务开发框架等，实现快速为客户定制需求，并结合科学的架构，使用敏捷的迭代式开发方法，快速实现客户的产品需求，
                    注重提高产品服务能力的同时，提升竞争力。差异化定制的软件产品，将为越来越多的企业客户带来巨大的效益提升。
                  </p>
                  <img src="../assets/imgs/about/fuben-640-640.jpg" alt="" />
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "../components/Header01.vue";
import Foot01 from "../components/Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.header_Div {
  max-width: 1600px;
  margin: 0 auto;
}
.el-main {
  padding: 0;
}

.page-box {
  // height: 100vh;
  margin-top: 10px;
}
._blank {
  height: 20px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
//
.bc_Div01 {
  width: 90%;
  height: 300px;
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  background-color: #effbfb;
}

.bc_Tabs {
  width: 65%;
  min-height: 300px;
  // display: flex;
  // justify-content: center;
  margin: 40px auto;
}
// *about页面tab导航文字样式
.about-tab-style {
  // background-color: blue;
  font-size: 18px;
}


.tab-pane-box {
  padding: 5px 40px;
  width: 80%;
  height: 470px;
}
.tab-pane-title{
  font-weight: 700;
  font-size: 20px;
}

.tab-pane-box > p {
  margin-top: 10px;
  text-indent: 2em;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 20px;
}
.tab-pane-box > img {
  width: 100%;
  height: auto;
}
</style>