<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <el-card>
          <div class="join_Outside">
            <div class="join_Bc">
              <div class="join_Text">
                <h2 class="join_H2">诚聘英才</h2>
                <p class="join_P">人力资源联系方式:025-52389832</p>
              </div>
            </div>

            <div>
              <div class="join_Atmosphere">
                <div class="" style="width: 40%">
                  <img
                    src="../assets/imgs/joinus2.jpg"
                    alt=""
                    class="join_img2"
                  />
                </div>
                <div class="" style="width: 50%; padding: 0px 20px 0px 100px">
                  <div>
                    <h3 class="join_H3">文化氛围</h3>
                  </div>
                  <div>
                    <h2 style="text-align: center; padding: 20px 0px 20px 0px">
                      人文为怀，科技汇海
                    </h2>
                  </div>
                  <div class="join_Text2">
                    <p>
                      人文为怀，科技汇海。公司坚持以人为本，汇聚四方科技精英，海纳八面商机，成就百年汇海。
                      淮（怀）海科技团队坚持研发创新，为服务单位（企业）提供最优质、最齐全的科研产品，科技引领助服务单位（企业）扬帆远航。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card>
          <div>
            <div style="width: 90%; margin-right: auto; margin-left: auto">
              <h2 style="margin-left:20px; margin-block: 10px;">工作环境</h2>
            </div>
            <div class="join_Environment">
              <el-row :gutter="20" align="middle" type="flex" >
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/joinEv2.jpg"
                      class="join_Image4"
                    />
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/joinEv3.jpg"
                      class="join_Image4"
                    />
                    
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/joinEv2.jpg"
                      class="join_Image4"
                    />
                    
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="../assets/imgs/joinEv3.jpg"
                      class="join_Image4"
                    />
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </div>
    </el-main>
    <el-footer style="height:200px; background-color: #507DC0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "../components/Header01.vue";
import Foot01 from "../components/Foot01.vue";
export default {
  data:{
    return:{

    }
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.el-main {
  padding: 0;
}
.page-box{
  // height: 100vh;
  margin-top: 10px;
}

._blank {
  height: 20px;
}
.el-card__body{
  padding: 0px;
}
.join_Outside{
  padding: 0 10px;
}
.join_Bc {
  width: 100%;
  height: 630px;
  background: url(../assets/imgs/join2.jpg) no-repeat 50%;
  background-size: cover;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}

//

//
.join_Text {
  height: 100%;
  padding: 100px;
}
.join_H2 {
  font-size: 28px;
  line-height: 64px;
  margin: 0 auto;
}
.join_P {
  font-size: 16px;
  margin: 0 auto;
}
.join_Atmosphere {
  display: flex;
  width: 90%; 
  padding: 20px 0px 10px 0px;
  justify-content:center;
}
.join_img2 {
  width: 100%;
  height: 400px;
}
.join_H3 {
  text-align: right;
  padding-right: 50px;
  font-size: 20px;
}
.join_Text2 {
  padding: 10px;
  font-size: 14px;
  text-indent: 2em;
}

.join_Image4 {
  max-width: 400px;
}
.join_Environment{
    width: 80%; 
    margin-right: auto; 
    margin-left: auto; 
    display:flex;
    justify-content:center;
    align-items:center;
}
</style>