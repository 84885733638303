<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <div>
          <el-card>
            <div class="bc_Div01">
              <img src="../../assets/imgs/news.png" alt="" />
            </div>
          </el-card>
        </div>
        <div>
          <div class="content_container">
            <div class="content_text">
              <div class="content_1">
                <h2>{{ content.title }}</h2>
                <p>
                  {{ content.paragraph1 }}
                </p>
               

                <p>
                  {{ content.paragraph2 }}
                </p>
               

                <p>
                  {{ content.paragraph3 }}
                </p>
                

                <p>
                  {{ content.paragraph4 }}
                </p>
                <img :src="imgUrl1" />
                <div class="content_footer">
                  <h1>关注公众号，了解更多</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "../Header01.vue";
import Foot01 from "../Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
      imgUrl1: require("../../assets/imgs/news/QQ图片20210917104842.jpg"),
      content: {
        title: "江苏淮海数码科技有限公司第一期实习生圆满完成实习任务",
        paragraph1:
          "根据南京铭和医院投资管理有限公司董事长张汉乐、江苏淮海数码科技有限公司执行董事张宁远指示精神，江苏淮海数码科技有限公司于2021年7月接收江苏科技大学两名实习同学来公司实习，现已圆满完成实习任务返校。",
        paragraph2:
          "江苏淮海数码科技有限公司教学实践部负责制定实习计划，指定专人负责，定期授课指导，召开座谈会征求意见，改进教学工作。",
        paragraph3:
          "实习期间，两位实习同学积极主动参与公司日常研发工作，理论结合于实践，出色完成各项任务的同时，理论知识、专业水平均得到了迅速提升，为日后职业生涯的长足发展打下坚实的基础。",
        paragraph4:
          "实习结束前，两位实习同学提交工作总结并作汇报，教学实践部对两位实习同学组织考核。公司领导对实习同学给予满意评价，对教学实践部工作给予肯定。",
      },
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-main {
  padding: 0;
}
.page-box {
  //   height: 100vh;
  margin-top: 10px;
}
//
.bc_Div01 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
}

// 产品内容样式
.content_container {
  max-width: 1600px;
  margin: auto;
}
.content_text {
  display: flex;
  justify-content: center;
}
.content_1 {
  width: 65%;
}
.content_1 > h2 {
  margin: 20px 0;
  font-size: 20px;
  color: #003399;
}
.content_1 > p {
  font-size: 15px;
  line-height: 28px;
  text-indent: 2em;
  margin: 15px 0;
  text-align: justify;
  text-justify: inter-ideograph;
}
.content_1 > img {
  display: flex;
  justify-content: center;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}
.content_footer {
  text-align: center;
  margin: 15px;
}
</style>