<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <div>
          <el-card>
            <div class="bc_Div01">
              <img src="../assets/imgs/news.png" alt="" />
            </div>
          </el-card>
        </div>
        <div>
          <div class="content_container">
            <div>
              
              <div class="content_text">
                <div class="content_1">
                  <div class="new-box-title">
                    <h2>News Center</h2>
                  </div>
                  <div v-for="(item, index) in tableData" :key="index">
                    <el-row type="flex" justify="space-between">
                      <el-col :span="16" class="title_col">
                        <a @click="goNewsPage(item.id)">
                          <el-link
                            icon="el-icon-share"
                            target="_blank"
                            :underline="false"
                            >{{ item.title }}</el-link>
                        </a>
                      </el-col>
                      <el-col :span="3" class="title_col">
                        {{ item.data }}
                      </el-col>
                    </el-row>
                  </div>
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="10"
                  >
                  </el-pagination>
                </div>
                <div class="new-advertisement">
                  <img src="../assets/imgs/solve_img2.jpg" alt="">
                </div>
              </div>
            </div>
            <div style="height:200px;">

            </div>
          </div>

        </div>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "./Header01.vue";
import Foot01 from "./Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
      tableData: [
        {
          id: 4,
          title: "江苏淮海数码科技有限公司组织节前安全工作检查",
          data: "2021-09-26",
        },
        {
          id: 1,
          title: "江苏淮海数码科技有限公司第一期实习生圆满完成实习任务",
          data: "2021-09-17",
        },
        {
          id: 2,
          title: "江苏淮海数码科技有限公司召开“铭和健康管理系统”研讨会",
          data: "2021-09-01",
        },
        {
          id: 3,
          title: "南京铭和医院投资管理有限公司张汉乐董事长莅临江苏淮海数码科技有限公司视察工作",
          data: "2021-06-17",
        },
        
      ],
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
  methods: {
    goNewsPage(id) {
      switch(id){
        case 2:
          this.$router.push("/new20210901");
          break;
        case 3:
          this.$router.push("/new20210630");
        break;
        case 1:
          this.$router.push("/new20210917");
        case 4:
          this.$router.push("/new20210926");
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-main {
  padding: 0;
}
.page-box {
  //   height: 100vh;
  margin-top: 10px;
}
//
.bc_Div01 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
}

// 产品内容样式
.content_container {
  max-width: 1600px;
  margin: auto;
}
.content_text {
  display: flex;
  justify-content: center;
}

.new-box-title {
  margin: 20px 0;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
  color: darkgrey;
}

.content_1 {
  width: 49%;
}
.content_1 > h2 {
  margin: 20px 0;
  font-size: 20px;
  color: #003399;
}
.content_1 > p {
  font-size: 15px;
  line-height: 28px;
  text-indent: 2em;
  margin: 15px 0;
  text-align: justify;
  text-justify: inter-ideograph;
}
.content_1 > img {
  display: flex;
  justify-content: center;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}

.new-advertisement {
  margin-top: 40px;
  width: 20%;
}
.new-advertisement img{
  width: 80%;
  height: auto;
  padding: 10px;
}

.content_footer {
  text-align: center;
  margin: 15px;
}

.title_col {
  display: flex;
  // justify-content: ;
  align-items: center;
  color: #222;


  .el-link {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 4px 2px;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*禁止换行*/
    text-overflow: ellipsis; /*省略号*/
  }
}

.el-pagination{
  margin: 15px 0;
}
</style>