<template>
  <el-container>
    <el-header>
      <div>
        <head01></head01>
      </div>
    </el-header>
    <el-main>
      <div class="page-box">
        <div>
          <el-card>
            <div class="bc_Div01">
              <img src="../../assets/imgs/news.png" alt="" />
            </div>
          </el-card>
        </div>
        <div>
          <div class="content_container">
            <div class="content_text">
              <div class="content_1">
                <h2>{{ content.title }}</h2>
                <p>
                  {{ content.paragraph1 }}
                </p>
                <img :src="imgUrl1" />
                <p>
                  {{ content.paragraph2 }}
                </p>
                <img :src="imgUrl2" />
                <div class="content_footer">
                  <h1>关注公众号，了解更多</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer style="height: 200px; background-color: #507dc0">
      <div>
        <foot01></foot01>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Header01 from "../Header01.vue";
import Foot01 from "../Foot01.vue";
export default {
  data() {
    return {
      activeName: "first",
      imgUrl1: require("../../assets/imgs/news/20210926-1.jpg"),
      imgUrl2: require("../../assets/imgs/news/20210926-2.jpg"),
      content: {
        title: "江苏淮海数码科技有限公司组织节前安全工作检查",
        paragraph1:
          "为切实做好公司节日期间安全管理工作，认真贯彻落实南京铭和医院投资管理有限公司关于抓好安全生产等工作指示精神，9月26日下午，江苏淮海数码科技有限公司总经理夏志学、副总经理王军带领公司所属部门领导开展节前安全工作检查。在检查中查看设备安全运行状态，设备存放是否符合标准，检查设备有无异常情况，确保设备正常运转，各种登统计是否符合规范要求。在检查中发现问题及时纠正,如在公司进门处安装摄像监控装置，并在下班前关闭电源等。",
        paragraph2:
          "夏志学总经理在检查中强调：一是安全生产责任重于泰山，必须警钟长鸣，常抓不懈；二是提高政治站位，深刻认识安全生产的极端重要性；三是安全生产无小事，树立安全生产“全员行动”的协同思维，深入开展风险隐患排查整治，坚决做到“一抓到底，见底清零”。",
      },
    };
  },
  components: {
    head01: Header01,
    foot01: Foot01,
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-main {
  padding: 0;
}
.page-box {
  //   height: 100vh;
  margin-top: 10px;
}
//
.bc_Div01 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
}

// 产品内容样式
.content_container {
  max-width: 1600px;
  margin: auto;
}
.content_text {
  display: flex;
  justify-content: center;
}
.content_1 {
  width: 65%;
}
.content_1 > h2 {
  margin: 20px 0;
  font-size: 20px;
  color: #003399;
}
.content_1 > p {
  font-size: 15px;
  line-height: 28px;
  text-indent: 2em;
  margin: 15px 0;
  text-align: justify;
  text-justify: inter-ideograph;
}
.content_1 > img {
  display: flex;
  justify-content: center;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}
.content_footer {
  text-align: center;
  margin: 15px;
}
</style>